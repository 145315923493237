import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, concatMap, map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { GenericInvoiceData, GenericInvoiceItemData, SubscriptionPlan } from '@dougs/subscription/dto';
import { SocialSubscriptionStateService } from '@dougs/subscription/shared';

@Injectable({
  providedIn: 'root',
})
export class SocialBillingInvoiceComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly socialSubscriptionStateService: SocialSubscriptionStateService,
  ) {}

  refreshSocialPlans$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    concatMap((company) => this.socialSubscriptionStateService.refreshSocialPlans(company.id)),
  );

  nextBillingDate$: Signal<Date | null> = toSignal(
    combineLatest([this.companyStateService.activeCompany$, this.socialSubscriptionStateService.socialPlans$]).pipe(
      map(([company, plans]): Date | null => {
        return this.extractNextBillingDateFromSocialPlansForCompany({ company, plans });
      }),
    ),
    { initialValue: null },
  );

  invoices$: Signal<
    { date: Date | string; amount: number; id: number; amountExcludingTaxes: number; items: GenericInvoiceItemData[] }[]
  > = toSignal(
    combineLatest([this.companyStateService.activeCompany$, this.socialSubscriptionStateService.socialPlans$]).pipe(
      map(([company, plans]): GenericInvoiceData[] => {
        return this.extractUpcomingInvoicesFromSocialPlansForCompany({ company, plans });
      }),
    ),
    { initialValue: [] },
  );

  private extractNextBillingDateFromSocialPlansForCompany({
    company,
    plans,
  }: {
    company: Company;
    plans: SubscriptionPlan[];
  }): Date | null {
    const currentPlan: SubscriptionPlan | undefined = plans.find(
      (plan) => plan.id === company.socialSubscription?.plan,
    );
    return currentPlan ? new Date(currentPlan.upcomingInvoices[1].date) : null;
  }

  private extractUpcomingInvoicesFromSocialPlansForCompany({
    company,
    plans,
  }: {
    company: Company;
    plans: SubscriptionPlan[];
  }): GenericInvoiceData[] {
    const currentPlan: SubscriptionPlan | undefined = plans.find(
      (plan) => plan.id === company.socialSubscription?.plan,
    );
    if (!currentPlan) {
      return [];
    }
    return currentPlan.upcomingInvoices.slice(1).map((invoice) => ({
      ...invoice,
    }));
  }
}
