import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { DividerComponent } from '@dougs/ds';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { BankCardNewComponent } from './bank-card/bank-card.component';
import { NextBillingNewComponent } from './next-billing/next-billing.component';
import { PlanNewComponent } from './plan/plan.component';
import { SocialPlanComponent } from './social-plan/social-plan.component';
import { SubscriptionPeriodNewComponent } from './subscription-period/subscription-period.component';
import { TermOfUseNewComponent } from './term-of-use/term-of-use.component';
import { TrialNewComponent } from './trial/trial.component';
import { VipNewComponent } from './vip/vip.component';

@Component({
  selector: 'dougs-subscription-new',
  standalone: true,
  imports: [
    CommonModule,
    DividerComponent,
    PlanNewComponent,
    NextBillingNewComponent,
    SubscriptionPeriodNewComponent,
    BankCardNewComponent,
    TermOfUseNewComponent,
    TrialNewComponent,
    VipNewComponent,
    SocialPlanComponent,
  ],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionNewComponent {
  constructor(
    public readonly companyStateService: CompanyStateService,
    public readonly userStateService: UserStateService,
  ) {}

  protected readonly USER_FLAG = USER_FLAG;
}
