import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import {
  ButtonComponent,
  ConfirmationModalComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { FieldComponent } from '@dougs/fields/ui';
import { OnboardingCreationStateService } from '@dougs/onboarding-creation/shared';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-legal-letter-engagement-modal',
  templateUrl: './legal-letter-engagement-modal.component.html',
  styleUrls: ['./legal-letter-engagement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    LoaderComponent,
    ModalContentDirective,
    PanelInfoComponent,
    NgFor,
    FieldComponent,
    FormsModule,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class LegalLetterEngagementModalComponent implements OnInit {
  constructor(
    public readonly companyStateService: CompanyStateService,
    private readonly sanitizer: DomSanitizer,
    private readonly modalService: ModalService,
    private readonly modalRef: ModalRef,
    private readonly partnerStateService: PartnerStateService,
    private readonly userStateService: UserStateService,
    public readonly fieldsStateService: FieldsStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly onboardingCreationStateService: OnboardingCreationStateService,
    private readonly metricsService: MetricsService,
  ) {}

  canCloseWithoutData = false;
  ownerIsCeoOrDirector = true;

  async ngOnInit(): Promise<void> {
    await this.partnerStateService.refreshPartners(this.companyStateService.activeCompany.id);
    const partners: Partner[] = await lastValueFrom(this.partnerStateService.partners$.pipe(take(1)));
    const ownerPartner: Partner | undefined = partners.find(
      (partner) => partner.naturalPersonId === this.userStateService.activeUser.profileId,
    );
    if (!ownerPartner) {
      this.canCloseWithoutData = true;
    } else if (!(ownerPartner.isCeo || ownerPartner.isDirector)) {
      this.ownerIsCeoOrDirector = false;
    }

    this.metricsService.pushMixpanelEvent('Onboarding Creation Engagement Letter Page Viewed');
    this.cdr.markForCheck();
  }

  get previewUri(): SafeResourceUrl | undefined {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${this.companyStateService.activeCompany.id}/engagement-letter`,
    );
  }

  async submit(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Onboarding Creation Engagement Letter Confirmation Page Viewed');
    const confirm = !!(
      await lastValueFrom(
        this.modalService.open<boolean>(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: "Je confirme l'acceptation de la Lettre de Mission ainsi que les Conditions Générales d'Utilisation.",
            yesText: 'Accepter',
            noText: 'Annuler',
            secondaryColor: true,
          },
        }).afterClosed$,
      )
    ).data;

    if (confirm) {
      this.modalRef.close(true);
      this.metricsService.pushMixpanelEvent('Onboarding Creation Engagement Letter Confirmation Signed');

      if (await toPromise(this.companyStateService.hasCompanyCreationOnboarding$)) {
        await this.router.navigateByUrl(this.routingService.createUrl([URL.ONBOARDING_CREATION]).toString());

        // TODO - Remove when update from Websockets has been implemented
        await this.onboardingCreationStateService.refreshOnboardingCreation(3000);
      }
    }
  }
}
