<form [formGroup]="formGroup" class="form-ask-for-sale-date" (ngSubmit)="onSubmit()">
  <div dougsModalTitle>
    <h6>{{ modalTitle }}</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info type="error" class="mb-16" *ngIf="errorMessage">
      <p class="small">
        {{ errorMessage }}
      </p>
    </dougs-panel-info>
    <dougs-avatar-message
      size="medium"
      class="mb-16"
      [fullName]="'Sarah Jaouani, Responsable Comptabilité'"
      [avatarUrl]="'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg'"
    >
      Bonjour,
      <br />
      Pour une bonne tenue de vos comptes et un bon suivi de vos obligations fiscales, nous avons besoin de certains
      documents. Merci de les attacher ci-dessous.
    </dougs-avatar-message>
    <dougs-panel-info type="default-light" class="dougs-panel-info">
      <div
        class="sales-platform-configuration"
        [ngClass]="{ 'mb-16': activeCompany.salesViaPlatformConfiguration.hasIoss }"
        *ngIf="activeCompany.salesViaPlatformConfiguration.hasOss"
      >
        <h5 class="mb-8">OSS</h5>
        <div class="mb-8">
          <label dougsFormFieldLabel for="ossDate">
            Je suis inscrit au guichet unique OSS-UE pour mes ventes à distance à partir du :
          </label>
          <dougs-input-datepicker
            [noMargin]="true"
            [options]="{ minDate: minDate }"
            label=""
            id="ossDate"
            formControlName="ossDate"
          >
          </dougs-input-datepicker>
        </div>
        <div>
          <label dougsFormFieldLabel for="ossInscription">Bordereau d'inscription OSS</label>
          <div class="file-list">
            <dougs-file-input (uploadFiles)="uploadOssFiles($event)" id="ossInscription"></dougs-file-input>
            <ng-container *ngFor="let attachment of activeCompany.ossInscriptions; trackBy: 'id' | trackBy">
              <dougs-file-pill
                (deleteFile)="deleteOssFile($event)"
                *ngIf="attachment.type === 'ossInscription'"
                [attachment]="attachment"
              ></dougs-file-pill>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="sales-platform-configuration" *ngIf="activeCompany.salesViaPlatformConfiguration.hasIoss">
        <h5 class="mb-8">IOSS</h5>
        <div class="mb-8">
          <label dougsFormFieldLabel for="iossDate">
            Je suis inscrit au guichet unique IOSS pour les ventes à distance de biens importés, vendus depuis mon
            propre site internet, à partir du :
          </label>
          <dougs-input-datepicker
            [options]="{ minDate: minDate }"
            label=""
            [noMargin]="true"
            formControlName="iossDate"
            id="iossDate"
          ></dougs-input-datepicker>
        </div>
        <div>
          <label dougsFormFieldLabel for="iossInscription">Bordereau d'inscription IOSS</label>
          <div class="file-list">
            <dougs-file-input id="iossInscription" (uploadFiles)="uploadIOssFiles($event)"></dougs-file-input>
            <ng-container *ngFor="let attachment of activeCompany.iossInscriptions; trackBy: 'id' | trackBy">
              <dougs-file-pill
                (deleteFile)="deleteIOssFile($event)"
                *ngIf="attachment.type === 'iossInscription'"
                [attachment]="attachment"
              ></dougs-file-pill>
            </ng-container>
          </div>
        </div>
      </div>
    </dougs-panel-info>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
    <dougs-button type="submit" color="primary">
      <i class="fal fa-check mr-4"></i>
      Valider
    </dougs-button>
  </div>
</form>
