import { inject, Injectable } from '@angular/core';
import { PromiseQueue } from '@dougs/core/queue';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { TaskService } from './task.service';

@Injectable()
export class TaskQueueService extends PromiseQueue {
  private readonly taskService: TaskService = inject(TaskService);
  private readonly controlPanelTasksStateService: ControlPanelTasksStateService = inject(ControlPanelTasksStateService);

  completeTask(task: Task, isSubTask = false, bypass = false): Promise<boolean> {
    return this.enqueue(() => this.taskService.completeTask(task, isSubTask, bypass));
  }

  unCompleteTask(task: Task, isSubTask = false): Promise<boolean> {
    return this.enqueue(() => this.taskService.unCompleteTask(task, isSubTask));
  }

  updateTask(task: Task): Promise<void> {
    return this.enqueue(() => this.controlPanelTasksStateService.updateTask(task));
  }

  assignTask(task: Task, assigneeId: number | null): Promise<void> {
    return this.enqueue(() => this.controlPanelTasksStateService.assignTask(task, assigneeId));
  }

  updateTaskStartDate(task: Task, startDate: string): Promise<boolean> {
    return this.enqueue(() => this.controlPanelTasksStateService.updateTaskStartDate(task, startDate));
  }
}
