import { AsyncPipe, CurrencyPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, TrackByPipe } from '@dougs/ds';
import { BillingInvoiceStateService, SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { BillingInvoiceComponentService } from '../../services/billing-invoice.component.service';
import { BillingInvoiceService } from '../../services/billing-invoice.service';
import { BillingInvoiceBlankSlateComponent } from './billing-invoice-blank-slate/billing-invoice-blank-slate.component';
import { BillingInvoiceRowComponent } from './billing-invoice-row/billing-invoice-row.component';
import { PendingBillingInvoiceComponent } from './pending-billing-invoice/pending-billing-invoice.component';
import { SocialPendingBillingInvoiceComponent } from './social-pending-billing-invoice/social-pending-billing-invoice.component';

@Component({
  selector: 'dougs-billing-invoices',
  templateUrl: './billing-invoices.component.html',
  styleUrls: ['./billing-invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PendingBillingInvoiceComponent,
    SocialPendingBillingInvoiceComponent,
    NgClass,
    BillingInvoiceBlankSlateComponent,
    NgFor,
    BillingInvoiceRowComponent,
    ButtonComponent,
    AsyncPipe,
    CurrencyPipe,
    TrackByPipe,
  ],
})
export class BillingInvoicesComponent {
  constructor(
    public readonly billingInvoiceService: BillingInvoiceService,
    public readonly billingInvoiceStateService: BillingInvoiceStateService,
    public readonly billingInvoiceComponentService: BillingInvoiceComponentService,
    public readonly userStateService: UserStateService,
    public readonly subscriptionStateService: SubscriptionStateService,
  ) {}
}
