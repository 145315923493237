import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { combineLatest, concatMap, filter, lastValueFrom, map, Observable, take, tap } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { InteractionsService } from '@dougs/core/interactions';
import { SocketService } from '@dougs/core/socket';
import { ModalService } from '@dougs/ds';
import { NotificationStateService } from '@dougs/notifications/shared';
import { NotValidatedOperationsStateService } from '@dougs/operations/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { ServicesStateService } from '@dougs/services/shared';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { CockpitStateService, UserTasksStateService } from '@dougs/task/shared';
import { ActionableTasksService } from '@dougs/task/task-actions';
import { User } from '@dougs/user/dto';
import { CollaboratorStateService, TeamStateService, UserService, UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';

@Injectable()
export class AppComponentService {
  shouldShowControlPanel$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) =>
        loggedInUser.isAccountantOrAdmin &&
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  refreshAllOnActiveUserChange$: Observable<void> = this.userStateService.activeUserIdChanged$.pipe(
    concatMap((activeUser) => this.refreshUserData(activeUser)),
  );

  refreshAllOnActiveCompanyChange$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.refreshCompanyData(activeCompany)),
  );

  refreshAllOnLoggedInUserChange$: Observable<User> = this.userStateService.loggedInUser$.pipe(
    tap((user: User) => {
      this.enableDarkMode(this.userStateService.hasDarkModeEnabled());
      this.socketService.bindUserById(user.id);
    }),
    filter((loggedInUser) => loggedInUser.isAccountantOrAdmin),
    tap(() => {
      this.interactionsService.addInteractions();
      void this.userStateService.refreshUsersInfo(); // Should be deprecated soon

      void this.collaboratorStateService.refreshCollaboratorsWithPersonalData();
      void this.teamStateService.refreshTeams();
    }),
  );

  openAcceptSubscriptionPlanPoliciesModal$ = combineLatest([
    this.subscriptionStateService.openAcceptSubscriptionPlanPoliciesModal$,
    this.companyStateService.activeCompanyIdChanged$,
  ]).pipe(
    concatMap(([_, activeCompany]) =>
      this.actionableTasksService.fetchAndExecuteAcceptSubscriptionPlanPolicies(activeCompany),
    ),
  );

  hasModalOpened = false;

  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly teamStateService: TeamStateService,
    private readonly userService: UserService,
    private readonly servicesStateService: ServicesStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly notificationStateService: NotificationStateService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly actionableTasksService: ActionableTasksService,
    private readonly socketService: SocketService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly interactionsService: InteractionsService,
    private readonly modalService: ModalService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
  ) {}

  private async refreshUserData(activeUser: User): Promise<void> {
    if (activeUser) {
      void this.cockpitStateService.refreshUnseenStatsOfCurrentUser();
      void this.userService.checkDestroyedUser();
    }
  }

  private async refreshCompanyData(activeCompany: Company): Promise<void> {
    if (activeCompany) {
      void this.userTasksStateService.refreshTasks(activeCompany);
      void this.notificationStateService.refreshNotifications(activeCompany);
      void this.servicesStateService.refreshServices(activeCompany);
      await this.companyServicesStateService.refreshServices(activeCompany.id);
      if (this.companyServicesStateService.services?.features.hasNotification) {
        void this.actionableTasksService.fetchAndExecuteActionableTasks(activeCompany);
      }
      if (this.companyServicesStateService.services?.accounting.shouldShowModuleLink) {
        void this.synchronizedAccountStateService.refreshSynchronizedAccounts(activeCompany.id);
        void this.notValidatedOperationsStateService.refreshOperationsCount(activeCompany.id);
      }
      this.socketService.bindCompanyById(activeCompany.id);
    }
  }

  async openSearchUserModal(event?: KeyboardEvent): Promise<void> {
    const loggedInUser: User = await lastValueFrom(this.userStateService.loggedInUser$.pipe(take(1)));
    event?.preventDefault();
    if (
      loggedInUser?.isAccountantOrAdmin &&
      !loggedInUser?.flags?.includes('restrictToAssignedCompanies') &&
      !this.hasModalOpened
    ) {
      const { UserSearchModalComponent } = await import('@dougs/user/ui');
      this.hasModalOpened = true;
      await lastValueFrom(this.modalService.open(UserSearchModalComponent).afterClosed$);
      this.hasModalOpened = false;
    }
  }

  async openCompanySummaryModal(event?: KeyboardEvent): Promise<void> {
    event?.preventDefault();
    if (this.userStateService.loggedInUser.isAccountantOrAdmin && !this.hasModalOpened) {
      this.hasModalOpened = true;
      const { CompanySummaryModalComponent } = await import('@dougs/company/ui');
      await lastValueFrom(this.modalService.open(CompanySummaryModalComponent).afterClosed$);
      this.hasModalOpened = false;
    }
  }

  enableDarkMode(enableDarkMode: boolean): void {
    if (enableDarkMode) {
      this.renderer.addClass(this.document.documentElement, 'dark-mode');
    } else {
      this.renderer.removeClass(this.document.documentElement, 'dark-mode');
    }
  }

  changeFaviconsByAccountingFirm(): void {
    if (AppConfig.settings.accountingFirm === 'Cogep') {
      (this.document.querySelector('#appIcon16') as HTMLLinkElement).href = 'images/favicon/cogep/favicon-16x16.png';
      (this.document.querySelector('#appIcon32') as HTMLLinkElement).href = 'images/favicon/cogep/favicon-32x32.png';
      (this.document.querySelector('#appIcon96') as HTMLLinkElement).href = 'images/favicon/cogep/favicon-32x32.png';
      (this.document.querySelector('#appIcon192') as HTMLLinkElement).href = 'images/favicon/cogep/favicon-32x32.png';
    }
  }
}
