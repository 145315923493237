import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonComponent } from '@dougs/ds';
import { BillingInvoiceStateService, SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { BillingInvoiceComponentService } from '../../../services/billing-invoice.component.service';
import { BillingInvoiceService } from '../../../services/billing-invoice.service';
import { BillingInvoiceBlankSlateComponent } from '../../billing-invoices/billing-invoice-blank-slate/billing-invoice-blank-slate.component';
import { PendingBillingInvoiceComponent } from '../../billing-invoices/pending-billing-invoice/pending-billing-invoice.component';
import { SocialPendingBillingInvoiceComponent } from '../../billing-invoices/social-pending-billing-invoice/social-pending-billing-invoice.component';
import { BillingInvoicesTableComponent } from '../billing-invoices-table/billing-invoices-table.component';

@Component({
  selector: 'dougs-billing-invoices-list',
  templateUrl: './billing-invoices-list.component.html',
  styleUrls: ['./billing-invoices-list.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    PendingBillingInvoiceComponent,
    BillingInvoiceBlankSlateComponent,
    ButtonComponent,
    BillingInvoicesTableComponent,
    SocialPendingBillingInvoiceComponent,
  ],
})
export class BillingInvoicesListComponent {
  constructor(
    public readonly billingInvoiceService: BillingInvoiceService,
    public readonly billingInvoiceStateService: BillingInvoiceStateService,
    public readonly billingInvoiceComponentService: BillingInvoiceComponentService,
    public readonly userStateService: UserStateService,
    public readonly subscriptionStateService: SubscriptionStateService,
  ) {}
}
