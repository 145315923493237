@if (companyStateService.activeCompany$ | async; as activeCompany) {
  @if (socialBillingInvoiceComponentService.refreshSocialPlans$ | async) {}
  <div class="form-layout mb-24">
    <div>
      <div class="pending-title mb-8">
        <h5>Votre prochaine facture Social</h5>
        <p class="ml-4 small">
          (à venir le {{ socialBillingInvoiceComponentService.nextBillingDate$() | date: 'PPP' }})
        </p>
      </div>
      @for (invoice of socialBillingInvoiceComponentService.invoices$(); track invoice.id) {
        <div class="mt-16">
          <dougs-billing-invoice [billingInvoice]="invoice" [canAddService]="false" [displayTitle]="false" />
        </div>
      }
    </div>
  </div>
}
