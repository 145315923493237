@if (billingInvoiceComponentService.refreshPendingBillingInvoices$ | async) {}
@if (billingInvoiceComponentService.refreshBalance$ | async) {}
@if (billingInvoiceComponentService.refreshDoubtfulBalance$ | async) {}
@if (billingInvoiceComponentService.refreshBillingInvoices$ | async) {}
@if (billingInvoiceComponentService.shouldShowAllInvoices$ | async) {}

@if (userStateService.loggedInUser$ | async; as user) {
  <div class="billing-invoice">
    @if (billingInvoiceStateService.pendingBillingInvoice$ | async; as pendingBillingInvoice) {
      <dougs-pending-billing-invoice [pendingBillingInvoice]="pendingBillingInvoice" />
    }
    <dougs-social-pending-billing-invoice />
    <div class="form-layout mb-8">
      <div class="billing-invoice__title">
        <h5>Vos factures</h5>
        @if (user?.isAccountantOrAdmin) {
          <i
            (click)="billingInvoiceComponentService.refreshBillingInvoices()"
            class="ml-8 color-admin fal fa-sync"
            [class.fa-spin]="billingInvoiceComponentService.isRefreshing$()"
          ></i>
        }
      </div>
    </div>
    @if ((billingInvoiceComponentService.billingInvoices$ | async)?.length === 0) {
      <div class="form-layout mb-16">
        <dougs-billing-invoice-blank-slate />
      </div>
    }
    @if ((billingInvoiceComponentService.billingInvoices$ | async)?.length > 0) {
      <dougs-billing-invoices-table />
    }
    @if (user?.isAccountantOrAdmin && user.flags.includes('canFinalizeBillingInvoices')) {
      <div class="form-layout">
        <dougs-button (click)="billingInvoiceService.addInvoice()" [fullWidth]="true" color="primary-light">
          <i class="fas fa-plus-circle color-primary mr-4"></i>
          Créer une facture
        </dougs-button>
      </div>
    }
  </div>
}
