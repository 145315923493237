<div dougsModalTitle>
  <h6>Rappel des obligations</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-avatar-message
    [avatarUrl]="obligationsReminderModalComponentService.task$ | async | taskActionAvatarUrl"
    [fullName]="obligationsReminderModalComponentService.task$ | async | taskActionCollaboratorName"
    size="medium"
  >
    <p>Bonjour {{ obligationsReminderModalComponentService.customerFirstName }},</p>
    <br />
    <p>
      Conformément à la lettre de mission acceptée, nous sommes au regret de constater des irrégularités sur votre
      compte. Est-ce que tout va bien de votre côté ?
    </p>
    <br />
    <p>
      Vous trouverez ci-joint le courrier de rappel des obligations avec le détail des éléments concernés. Pouvez-vous
      en prendre note et nous faire un retour dans les meilleurs délais s'il vous plait ?
    </p>
    <br />
    <p>
      N'hésitez pas à revenir vers nous via le chat ou par téléphone pour que nous puissions discuter de votre situation
      : notre but est bien de vous aider, nous sommes là pour ça.
    </p>
    <br />
    <p>
      Je vous remercie par avance pour votre retour, nous restons disponibles pour vous accompagner. Je vous souhaite
      une excellente journée.
    </p>
  </dougs-avatar-message>
  @for (attachment of obligationsReminderModalComponentService.attachments$ | async; track attachment.id) {
    <dougs-file-details class="mt-16" [attachment]="attachment"> </dougs-file-details>
  }
</div>
<div dougsModalFooter>
  <dougs-button type="button" [dougsModalClose]="true">J'ai pris connaissance du courrier</dougs-button>
</div>
