@if (companyStateService.activeCompany$ | async; as activeCompany) {
  <div class="form-layout mb-16">
    <div class="subscription-line">
      <div class="line-title">Pack social choisi</div>
      <div class="line-content">
        {{ activeCompany.socialSubscription?.planDescriptor?.name || 'Aucun abonnement social choisi' }}
        @if (activeCompany.socialSubscription?.planDescriptor?.name) {
          @if (!activeCompany.socialSubscription?.isAnnuallyPaid) {
            {{ activeCompany.socialSubscription?.planDescriptor.amount * activeCompany.socialSubscription?.quantity }}
            €/mois
          }
          @if (activeCompany.socialSubscription?.isAnnuallyPaid) {
            (paiement annuel)
          }
          @if (activeCompany.socialSubscription?.suspended) {
            Abonnement résilié
          }
        }
      </div>
    </div>

    <div class="form-layout__no-grow self-end button-container">
      <dougs-button
        [fullWidth]="true"
        size="small"
        color="secondary"
        (click)="socialSubscriptionComponentService.openSocialPlansModal()"
      >
        Voir les packs de gestion sociale
      </dougs-button>
    </div>
  </div>
}
