<ng-container *ngIf="billingInvoiceComponentService.refreshPendingBillingInvoices$ | async"></ng-container>
<ng-container *ngIf="billingInvoiceComponentService.refreshBalance$ | async"></ng-container>
<ng-container *ngIf="billingInvoiceComponentService.refreshDoubtfulBalance$ | async"></ng-container>
<ng-container *ngIf="billingInvoiceComponentService.refreshBillingInvoices$ | async"></ng-container>
<ng-container *ngIf="billingInvoiceComponentService.shouldShowAllInvoices$ | async"></ng-container>

<div class="settings-card__content">
  <div>
    <div class="settings-card__sticky-column-title">
      <h5>Facture Dougs</h5>
    </div>
  </div>
  <div>
    <div class="billing-invoice" *ngIf="userStateService.loggedInUser$ | async as user">
      <dougs-pending-billing-invoice
        *ngIf="billingInvoiceStateService.pendingBillingInvoice$ | async as pendingBillingInvoice"
        [pendingBillingInvoice]="pendingBillingInvoice"
      ></dougs-pending-billing-invoice>

      <dougs-social-pending-billing-invoice />

      <div class="form-layout mb-8">
        <div class="billing-invoice__title">
          <h5>Vos factures</h5>
          <i
            *ngIf="user?.isAccountantOrAdmin"
            (click)="billingInvoiceComponentService.refreshBillingInvoices()"
            class="ml-8 color-admin fal fa-sync"
            [ngClass]="{ 'fa-spin': billingInvoiceComponentService.isRefreshing$() }"
          ></i>
        </div>
      </div>
      <div class="form-layout mb-16" *ngIf="(billingInvoiceComponentService.billingInvoices$ | async)?.length === 0">
        <dougs-billing-invoice-blank-slate></dougs-billing-invoice-blank-slate>
      </div>
      <div class="form-layout mb-16" *ngIf="(billingInvoiceComponentService.billingInvoices$ | async)?.length > 0">
        <div class="table billing-invoice__list">
          <table class="mb-8">
            <thead>
              <tr>
                <th class="custom-padding px-16 py-8">Mois</th>
                <th class="custom-padding pr-16 py-8"></th>
                <th class="custom-padding right pr-16 py-8">Total HT</th>
                <th class="custom-padding right pr-16 py-8">Total TTC</th>
                <th class="custom-padding center pr-16 py-8">Statut</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <dougs-billing-invoice-row
                *ngFor="
                  let billingInvoice of (billingInvoiceComponentService.showAllInvoices$()
                    ? billingInvoiceComponentService.billingInvoices$
                    : billingInvoiceComponentService.slicedBillingInvoices$
                  ) | async;
                  let last = last;
                  trackBy: 'id' | trackBy
                "
                [billingInvoice]="billingInvoice"
                [hasBorder]="!last || billingInvoiceComponentService.showAllInvoices$()"
              ></dougs-billing-invoice-row>
            </tbody>
          </table>
          <div class="billing-invoice__list__see-more" *ngIf="billingInvoiceComponentService.showMore$()">
            <p class="tiny pointer" (click)="billingInvoiceComponentService.expandInvoices()">
              Afficher toutes les factures ({{ billingInvoiceComponentService.billingInvoicesToShow$ | async }})
              <i class="color-primary-700 fal fa-chevron-down"></i>
            </p>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="user.isAccountantOrAdmin && (billingInvoiceComponentService.billingInvoices$ | async)?.length > 0"
      >
        <ng-container *ngIf="subscriptionStateService.balance$ | async as balance">
          <div class="form-layout mb-8">
            <div class="px-16 balance">
              <p class="color-admin small">
                Solde client {{ balance.sum !== 0 ? (balance.sum > 0 ? 'débiteur' : 'créditeur') : '' }} (
                <span
                  class="color-primary balance__details"
                  (click)="billingInvoiceComponentService.openSubscriptionDetailsModal()"
                >
                  détails
                </span>
                )
              </p>
              <p class="small">{{ balance.sum | currency: 'EUR' }}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="subscriptionStateService.doubtfulBalance$ | async as balance">
          <ng-container *ngIf="balance.sum !== 0">
            <div class="form-layout mb-8">
              <div class="px-16 balance">
                <p class="color-admin small">
                  Solde client douteux {{ balance.sum !== 0 ? (balance.sum > 0 ? 'débiteur' : 'créditeur') : '' }}
                </p>
                <p class="small">{{ balance.sum | currency: 'EUR' }}</p>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <div class="form-layout" *ngIf="user.isAccountantOrAdmin && user.flags.includes('canFinalizeBillingInvoices')">
        <dougs-button (click)="billingInvoiceService.addInvoice()" [fullWidth]="true" color="primary-light">
          <i class="fas fa-plus-circle color-primary mr-4"></i>
          Créer une facture
        </dougs-button>
      </div>
    </div>
  </div>
</div>
