import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { COMPANY_FLAG, CompanyStateService } from '@dougs/company/shared';
import { DougsDatePipe } from '@dougs/ds';
import { SocialBillingInvoiceComponentService } from '../../../services/social-billing-invoice.component.service';
import { BillingInvoiceComponent } from '../billing-invoice/billing-invoice.component';

@Component({
  selector: 'dougs-social-pending-billing-invoice',
  templateUrl: './social-pending-billing-invoice.component.html',
  styleUrls: ['./social-pending-billing-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [SocialBillingInvoiceComponentService],
  imports: [NgFor, BillingInvoiceComponent, DougsDatePipe, NgIf, AsyncPipe],
})
export class SocialPendingBillingInvoiceComponent {
  constructor(
    protected readonly socialBillingInvoiceComponentService: SocialBillingInvoiceComponentService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  protected readonly COMPANY_FLAG = COMPANY_FLAG;
}
