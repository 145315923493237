@if (companyStateService.activeCompany$ | async; as activeCompany) {
  @if (!activeCompany.subscription?.isFreeForever) {
    <div class="settings-subscription">
      @if (activeCompany.subscription?.plan) {
        <dougs-plan />
        <dougs-social-plan />
      } @else if (activeCompany.isCreated) {
        <dougs-trial />
      }
      <dougs-divider class="mb-16" />
      <dougs-next-billing />
      <dougs-subscription-period />
      <dougs-bank-card />
      <dougs-term-of-use />
    </div>
  } @else {
    <dougs-vip />
  }
}
