@if (companyStateService.activeCompany$ | async; as activeCompany) {
  <div class="form-grid">
    @if (!activeCompany.subscription?.isFreeForever) {
      @if (activeCompany.subscription?.plan) {
        <dougs-plan-new [company]="activeCompany" />
        <dougs-social-plan />
      } @else if (!activeCompany.subscription?.plan && activeCompany.isCreated) {
        <dougs-trial-new [company]="activeCompany" />
      }
      @if (activeCompany?.subscription?.nextBillingAt; as nextBillingAt) {
        <dougs-divider />
        <dougs-next-billing-new [nextBillingAt]="nextBillingAt" />
      }
      @if (
        (userStateService.loggedInUser$ | async)?.flags?.includes(USER_FLAG.CAN_MANAGE_PERIOD_ENDS_AT_SUBSCRIPTION)
      ) {
        <dougs-divider />
        <dougs-subscription-period-new />
      }
      @if (activeCompany.subscription?.plan || activeCompany.subscription.cardBrand) {
        <dougs-divider />
        <dougs-bank-card-new [company]="activeCompany" />
      }
      <dougs-term-of-use-new [company]="activeCompany" />
    } @else {
      <dougs-vip-new />
    }
  </div>
}
