import { Inject, Injectable } from '@angular/core';
import { concatMap, Observable, of, ReplaySubject } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { MODAL_DATA } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { FourretoutTaskStateService } from '@dougs/task/shared';

@Injectable()
export class ObligationsReminderModalComponentService {
  private readonly taskSubject: ReplaySubject<Task> = new ReplaySubject<Task>(1);
  task$: Observable<Task> = this.taskSubject.asObservable();

  customerFirstName: string | undefined;

  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
  ) {
    this.taskSubject.next(task);
    this.customerFirstName = task.metadata?.customerFirstName;
  }

  attachments$: Observable<Attachment[]> = this.task$.pipe(
    concatMap((task) =>
      task.metadata.obligationsReminderAttachmentId
        ? this.fourretoutTaskStateService.getAttachmentsByIds(task, [task.metadata.obligationsReminderAttachmentId])
        : of([]),
    ),
  );
}
