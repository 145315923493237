import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Coupon } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class CouponHttpService {
  constructor(private readonly http: HttpClient) {}

  createCouponFromUser(code: string): Observable<Coupon | null> {
    return this.http.post<Coupon>(
      `${AppConfig.settings.legacyApiServerUrl}/coupons`,
      { code },
      {
        headers: {
          'X-Client-Id': AppConfig.settings.legacyApiServerClientId,
        },
      },
    );
  }

  createCouponFromAdmin(companyId: number, code: string): Observable<Coupon | null> {
    return this.http.post<Coupon>(`${AppConfig.settings.legacyApiServerUrl}/coupons/admin`, { code, companyId });
  }

  getCoupons(companyId: number): Observable<Coupon[]> {
    return this.http.get<Coupon[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/coupons`);
  }

  deleteCoupon(couponId: number): Observable<void> {
    return this.http.delete<void>(`${AppConfig.settings.legacyApiServerUrl}/coupons/admin/${couponId}`);
  }
}
