import { AsyncPipe, CurrencyPipe, NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { AppConfig } from '@dougs/core/config';
import { AvatarComponent, DougsDatePipe, TooltipDirective } from '@dougs/ds';
import { BillingInvoice } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';
import { BillingInvoiceComponentService } from '../../../services/billing-invoice.component.service';

@Component({
  selector: 'dougs-billing-invoice-row',
  templateUrl: './billing-invoice-row.component.html',
  styleUrls: ['./billing-invoice-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, AvatarComponent, TooltipDirective, AsyncPipe, TitleCasePipe, CurrencyPipe, DougsDatePipe],
})
export class BillingInvoiceRowComponent {
  private _billingInvoice!: BillingInvoice;

  canChargeInvoice = false;

  @HostListener('click')
  onClick(): void {
    if (this.billingInvoice && this.userStateService.loggedInUser.isAccountantOrAdmin) {
      this.billingInvoiceComponentService.openPaymentDetailsModal(this.billingInvoice);
    }
  }

  @Input()
  hasBorder = false;

  @Input()
  set billingInvoice(billingInvoice: BillingInvoice) {
    this._billingInvoice = billingInvoice;

    this.canChargeInvoice = this.billingInvoiceComponentService.canChargeInvoice(billingInvoice);
  }

  get billingInvoice() {
    return this._billingInvoice;
  }

  constructor(
    public userStateService: UserStateService,
    public billingInvoiceComponentService: BillingInvoiceComponentService,
    private readonly window: Window,
  ) {}

  public downloadInvoice(e: MouseEvent): void {
    e.stopPropagation();
    this.window.open(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${this.billingInvoice.billedCompanyId}/billing-invoices/${this.billingInvoice.id}/actions/download`,
      '_blank',
    );
  }
}
