import { CurrencyPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  TrackByPipe,
} from '@dougs/ds';
import { EcommerceStateService } from '@dougs/ecommerce/shared';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-sales-via-platform-document-upload-modal',
  templateUrl: './sales-via-platform-document-upload-modal.component.html',
  styleUrls: ['./sales-via-platform-document-upload-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    PanelInfoComponent,
    AvatarMessageComponent,
    NgClass,
    LabelFormFieldDirective,
    InputDatepickerComponent,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
  ],
})
export class SalesViaPlatformDocumentUploadModalComponent implements OnInit, OnDestroy {
  activeCompany!: Company;
  companySubscription!: Subscription;
  hasOss: boolean | null = false;
  hasIoss: boolean | null = false;
  errorMessage?: string;
  modalTitle = '';
  minDate: Date = new Date('2021-06-30');

  formGroup!: FormGroup;

  get ossDate(): AbstractControl | null {
    return this.formGroup.get('ossDate');
  }
  get iossDate(): AbstractControl | null {
    return this.formGroup.get('iossDate');
  }

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly companyStateService: CompanyStateService,
    private readonly ecommerceStateService: EcommerceStateService,
    private readonly fb: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.companySubscription = this.companyStateService.activeCompany$.subscribe((company) => {
      this.activeCompany = company;
      this.hasOss = company.salesViaPlatformConfiguration.hasOss;
      this.hasIoss = company.salesViaPlatformConfiguration.hasIoss;

      this.buildForm();

      this.modalTitle =
        (this.hasOss || this.hasIoss ? "Attachez vos bordereaux d'inscription à l'" : '') +
        (this.hasOss ? 'OSS' : '') +
        (this.hasOss && this.hasIoss ? " et à l'" : '') +
        (this.hasIoss ? 'IOSS' : '');

      this.cdr.markForCheck();
    });
  }

  buildForm(): void {
    this.formGroup = this.fb.group({
      ossDate: [
        this.activeCompany.accountingConfiguration.ossActivationDate,
        this.activeCompany.salesViaPlatformConfiguration.hasOss ? Validators.required : null,
      ],
      iossDate: [
        this.activeCompany.accountingConfiguration.iossActivationDate,
        this.activeCompany.salesViaPlatformConfiguration.hasIoss ? Validators.required : null,
      ],
    });
  }

  getErrorMessage(): string {
    const missingOssDetails: boolean = !!this.hasOss && (!this.ossDate?.value || !this.activeCompany.ossInscriptions);
    const missingIossDetails: boolean =
      !!this.hasIoss && (!this.iossDate?.value || !this.activeCompany.iossInscriptions);

    if (missingOssDetails && missingIossDetails) {
      return `Vous devez nous transmettre vos dates et une copie de vos bordereaux d'inscription à l'OSS et à l'IOSS pour pouvoir valider cette tâche.`;
    }

    if (missingOssDetails) {
      return `Vous devez nous transmettre votre date et une copie de votre bordereau d'inscription à l'OSS pour pouvoir valider cette tâche.`;
    }

    if (missingIossDetails) {
      return `Vous devez nous transmettre votre date et une copie de votre bordereau d'inscription à l'IOSS pour pouvoir valider cette tâche.`;
    }

    return `Vous devez nous transmettre une copie de votre bordereau d'inscription pour pouvoir valider cette tâche.`;
  }

  async uploadOssFiles(files: FileList): Promise<void> {
    await this.companyStateService.uploadAttachments(this.activeCompany, files, 'ossInscriptions', 'ossInscription');
  }

  async deleteOssFile(attachment: Attachment): Promise<void> {
    await this.companyStateService.deleteAttachments(this.activeCompany, attachment, 'ossInscriptions', true);
  }

  async uploadIOssFiles(files: FileList): Promise<void> {
    await this.companyStateService.uploadAttachments(this.activeCompany, files, 'iossInscriptions', 'iossInscription');
  }

  async deleteIOssFile(attachment: Attachment): Promise<void> {
    await this.companyStateService.deleteAttachments(this.activeCompany, attachment, 'iossInscriptions', true);
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    const { ossInscriptions, iossInscriptions } = this.activeCompany;

    if (this.formGroup.invalid || (this.hasOss && !ossInscriptions) || (this.hasIoss && !iossInscriptions)) {
      this.errorMessage = this.getErrorMessage();
      return;
    }

    if (this.hasOss && ossInscriptions) {
      await this.ecommerceStateService.updateOssActivationDate(this.activeCompany, this.ossDate?.value);
    }

    if (this.hasIoss && iossInscriptions) {
      await this.ecommerceStateService.updateIOssActivationDate(this.activeCompany, this.iossDate?.value);
    }

    if ((this.hasOss && ossInscriptions) || (this.hasIoss && iossInscriptions)) {
      await this.ecommerceStateService.lockDateSalesViaPlatformConfiguration(this.activeCompany);
      this.modalRef.close(this.data.task);
    }
  }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }
}
