import { Routes } from '@angular/router';

export const REFERRER_DASHBOARD_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/referrer-dashboard/referrer-dashboard.component').then((c) => c.ReferrerDashboardComponent),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const REFERRAL_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/referral/referral.component').then((c) => c.ReferralComponent),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
