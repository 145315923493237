@if (companyStateService.activeCompany$ | async; as activeCompany) {
  <div class="subscription-line">
    <div class="subscription-line__left">
      <p class="small color-primary-700">Pack social choisi</p>
      <p class="small">
        {{ activeCompany.socialSubscription?.planDescriptor?.name || 'Aucun abonnement social choisi' }}
        @if (activeCompany.socialSubscription?.planDescriptor?.name) {
          @if (!activeCompany.socialSubscription?.isAnnuallyPaid) {
            {{ activeCompany.socialSubscription?.planDescriptor.amount * activeCompany.socialSubscription?.quantity }}
            €/mois
          }
          @if (activeCompany.socialSubscription?.isAnnuallyPaid) {
            (paiement annuel)
          }
          @if (activeCompany.socialSubscription?.suspended) {
            Abonnement résilié
          }
        }
      </p>
    </div>
    <div class="subscription-line__right">
      <dougs-button
        [fullWidth]="true"
        size="small"
        color="secondary"
        (click)="socialSubscriptionComponentService.openSocialPlansModal()"
      >
        Voir les packs de gestion sociale
      </dougs-button>
      @if (canUserSuspendSocialSubscription$ | async) {
        <dougs-button
          [fullWidth]="true"
          size="small"
          color="delete"
          (click)="socialSubscriptionComponentService.handleSocialSuspensionModal()"
        >
          Résilier
        </dougs-button>
      }
    </div>
  </div>
}
