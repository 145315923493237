import { Injectable } from '@angular/core';
import { addDays, differenceInDays, endOfDay, isAfter, isBefore, isSameDay, subDays } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';

interface SurveyReminderData {
  targetDate: Date | null;
  description: string;
  remainingDays?: number;
  elapseTimePercentage?: number;
}

@Injectable()
export class AccountingSurveyReminderComponentService {
  constructor(
    private readonly userTasksStateService: UserTasksStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
  ) {}

  accountingSurveyReminderData$: Observable<SurveyReminderData> = combineLatest([
    this.userTasksStateService.tasks$,
    this.companyStateService.activeCompany$,
    this.accountingYearStateService.activeAccountingYear$,
  ]).pipe(
    map(([userTasks, activeCompany, activeAccountingYear]) =>
      this.getDescriptionAndRemainingDays(
        activeAccountingYear,
        activeCompany,
        userTasks.find((task) => task.code === 'customer:accountingSurvey'),
      ),
    ),
  );

  private getDescriptionAndRemainingDays(
    activeAccountingYear: AccountingYear,
    activeCompany: Company,
    accountingSurveyTask?: Task,
  ): SurveyReminderData {
    const surveyReminderData: SurveyReminderData = {
      targetDate: null,
      description: '',
    };
    if (accountingSurveyTask && activeAccountingYear && activeCompany) {
      surveyReminderData.targetDate = this.getTargetDate(accountingSurveyTask, activeAccountingYear, activeCompany);
      surveyReminderData.description = this.getDescription(accountingSurveyTask, activeAccountingYear, activeCompany);
      if (surveyReminderData.targetDate) {
        surveyReminderData.remainingDays = this.getRemainingDays(surveyReminderData.targetDate);
        surveyReminderData.elapseTimePercentage = this.getElapseTimePercentage(
          surveyReminderData.targetDate,
          this.getClosingDate(activeAccountingYear),
          surveyReminderData.remainingDays,
        );
      }
    }

    return surveyReminderData;
  }

  private getClosingDate(accountingYear: AccountingYear): Date {
    return endOfDay(new Date(accountingYear.closingDate));
  }

  private getBonusDate(closingDate: Date): Date {
    return addDays(closingDate, 31);
  }

  private getDueDate(accountingSurveyTask: Task): Date {
    return endOfDay(new Date(accountingSurveyTask.dueDate));
  }

  private getWarningDate(targetDate: Date): Date {
    return subDays(targetDate, 5);
  }

  private getTerminationDate(targetDate: Date): Date {
    return addDays(targetDate, 15);
  }

  private shouldShowBonusWithAGOIncluded(activeCompany: Company, bonusDate: Date): boolean {
    return !!activeCompany.subscription?.isAGOIncluded && isBefore(new Date(), bonusDate);
  }

  private shouldShowBonusExcludingBNC(activeCompany: Company, bonusDate: Date): boolean {
    return activeCompany.category !== 'bnc' && isBefore(new Date(), bonusDate);
  }

  private shouldShowNormal(warningDate: Date): boolean {
    return isBefore(new Date(), warningDate) || isSameDay(new Date(), warningDate);
  }

  private shouldShowWarning(warningDate: Date, targetDate: Date): boolean {
    return isAfter(new Date(), warningDate) && (isBefore(new Date(), targetDate) || isSameDay(new Date(), targetDate));
  }

  private shouldShowTermination(targetDate: Date, terminationDate: Date): boolean {
    return (
      isAfter(new Date(), targetDate) &&
      (isBefore(new Date(), terminationDate) || isSameDay(new Date(), terminationDate))
    );
  }

  private getRemainingDays(targetDate: Date): number {
    return Math.abs(differenceInDays(new Date(), targetDate));
  }

  private getElapseTimePercentage(targetDate: Date, closingDate: Date, remainingDays: number): number {
    const periodLength: number = Math.abs(differenceInDays(targetDate, closingDate));
    let percentage: number = 100 - Math.round((remainingDays / periodLength) * 100);
    percentage = percentage < 10 ? 10 : percentage;
    return percentage <= 100 ? percentage : 100;
  }

  private shouldShowReminder(closingDate: Date): boolean {
    return isAfter(new Date(), closingDate) || isSameDay(new Date(), closingDate);
  }

  private getTargetDate(
    accountingSurveyTask: Task,
    accountingYear: AccountingYear,
    activeCompany: Company,
  ): Date | null {
    if (accountingSurveyTask && accountingYear && activeCompany) {
      const closingDate: Date = this.getClosingDate(accountingYear);
      if (this.shouldShowReminder(closingDate)) {
        const bonusDate: Date = this.getBonusDate(closingDate);
        const targetDate: Date = this.getDueDate(accountingSurveyTask);
        const warningDate: Date = this.getWarningDate(targetDate);
        const terminationDate: Date = this.getTerminationDate(new Date(accountingSurveyTask.dueDate));
        if (
          this.shouldShowBonusWithAGOIncluded(activeCompany, bonusDate) ||
          this.shouldShowBonusExcludingBNC(activeCompany, bonusDate)
        ) {
          return bonusDate;
        } else if (this.shouldShowNormal(warningDate)) {
          return targetDate;
        } else if (this.shouldShowWarning(warningDate, targetDate)) {
          return targetDate;
        } else if (this.shouldShowTermination(targetDate, terminationDate)) {
          return terminationDate;
        }
      }
    }

    return null;
  }

  private getDescription(accountingSurveyTask: Task, accountingYear: AccountingYear, activeCompany: Company): string {
    if (accountingSurveyTask && accountingYear && activeCompany) {
      const closingDate: Date = this.getClosingDate(accountingYear);
      if (this.shouldShowReminder(closingDate)) {
        const bonusDate: Date = this.getBonusDate(closingDate);
        const targetDate: Date = this.getDueDate(accountingSurveyTask);
        const warningDate: Date = this.getWarningDate(targetDate);
        const terminationDate: Date = this.getTerminationDate(new Date(accountingSurveyTask.dueDate));
        if (this.shouldShowBonusWithAGOIncluded(activeCompany, bonusDate)) {
          return "pour remplir votre questionnaire bilan et bénéficier d'un traitement prioritaire";
        } else if (this.shouldShowBonusExcludingBNC(activeCompany, bonusDate)) {
          return "pour remplir votre questionnaire bilan et ainsi bénéficier d'une remise de 50€ sur la prestation d'<a href='https://www.dougs.fr/blog/assemblee-generale/' target='_blank'>AGO</a>";
        } else if (isBefore(new Date(), warningDate)) {
          return 'pour remplir votre questionnaire bilan';
        } else if (this.shouldShowWarning(warningDate, targetDate)) {
          return 'pour remplir votre questionnaire bilan ou vous encourez une pénalité de 150€';
        } else if (this.shouldShowTermination(targetDate, terminationDate)) {
          return 'pour remplir votre questionnaire bilan avant rupture de la mission';
        }
      }
    }

    return '';
  }
}
