import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { addDays, format, isAfter, isBefore, parse } from 'date-fns';
import { concatMap, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DatePill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskQueueService } from '../../task-queue.service';

@Injectable()
export class TaskSnoozeComponentService {
  private readonly taskQueueService: TaskQueueService = inject(TaskQueueService);

  private readonly _loading$: WritableSignal<boolean> = signal(false);
  readonly loading$: Signal<boolean> = this._loading$.asReadonly();

  private readonly _task$: WritableSignal<Task | null> = signal(null);

  get task$(): Signal<Task | null> {
    return this._task$.asReadonly();
  }

  setTask(task: Task) {
    this._task$.set(task);
  }

  isSnoozed$: Signal<boolean> = computed(() => this.isSnoozed(this.task$()));

  showUnSnoozed$: Signal<boolean> = computed(() => this.showUnSnoozed(this.task$()));

  datePickerPills: DatePill[] = [
    { label: 'Demain', date: addDays(new Date(), 1) },
    { label: 'Dans 3 jours', date: addDays(new Date(), 3) },
    { label: 'Dans 7 jours', date: addDays(new Date(), 7) },
  ];

  snoozeDateControl: FormControl = new FormControl<string | null>('');

  snoozeDateControlValueChanges$: Observable<void> = this.snoozeDateControl.valueChanges.pipe(
    distinctUntilChanged(),
    concatMap((dateString) => {
      const date = parse(dateString, 'dd/MM/yyyy', new Date());
      return this.updateSnoozeDate(format(date, 'yyyy-MM-dd'));
    }),
  );

  async unSnooze(): Promise<void> {
    await this.updateSnoozeDate(format(new Date(), 'yyyy-MM-dd'));
  }

  private isSnoozed(task: Task | null): boolean {
    if (!task) {
      return false;
    }

    return isAfter(new Date(task.startDate), new Date());
  }

  private showUnSnoozed(task: Task | null): boolean {
    if (!task) {
      return false;
    }

    const dateCheck: boolean = isBefore(new Date(task.startDate), new Date());
    const seenCheck: boolean = task.assigneeSeenAt
      ? isAfter(new Date(task.startDate), new Date(task.assigneeSeenAt))
      : false;
    return dateCheck && seenCheck;
  }

  private async updateSnoozeDate(dateString: string): Promise<void> {
    const task = this.task$();
    if (!task) {
      return;
    }
    this._loading$.set(true);
    await this.taskQueueService.updateTask({ ...task, startDate: dateString });
    this._loading$.set(false);
  }
}
