import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ButtonComponent } from '@dougs/ds';
import { SocialSubscriptionComponentService } from '../../../services/social-subscription.component.service';

@Component({
  selector: 'dougs-social-plan',
  templateUrl: './social-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [SocialSubscriptionComponentService],
  imports: [ButtonComponent, AsyncPipe],
})
export class SocialPlanComponent {
  constructor(
    public readonly companyStateService: CompanyStateService,
    public metricsService: MetricsService,
    protected readonly socialSubscriptionComponentService: SocialSubscriptionComponentService,
  ) {}
}
