import { Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerAccountingInitialAppointmentService extends TaskActionService {
  protected _label = 'Prendre rendez-vous';
  protected _isAvailable = true;
  protected _tag = 'Rendez-vous';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-calendar-check';

  constructor(
    private readonly window: Window,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    this.window.open(task.metadata.calendlyUrl ?? this.generateCalendlyUrl(), '_blank');
  }

  private generateCalendlyUrl(): string {
    const activeCompany: Company = this.companyStateService.activeCompany;
    const salesViaPlatform: boolean | null = activeCompany.salesViaPlatformConfiguration.salesViaPlatform;

    this.metricsService.pushMixpanelEvent('Accounting Subscription Onboarding Calendly Link Clicked', {
      appointmentType: this.getAppointmentType(salesViaPlatform),
      location: 'todoPage',
    });

    const queryString: string = Object.entries(this.getQs(salesViaPlatform, activeCompany))
      .map(([k, v]) => `${k}=${encodeURIComponent(v ?? '')}`)
      .join('&');
    return `${this.getPath(salesViaPlatform)}?${queryString}`;
  }

  private getAppointmentType(salesViaPlatform: boolean | null): string {
    if (salesViaPlatform === null) {
      return 'activity type';
    }
    return salesViaPlatform ? 'e-commerce' : 'basic';
  }

  private getPath(salesViaPlatform: boolean | null): string {
    if (salesViaPlatform === null) {
      return 'https://calendly.com/d/y66-nm4-wdv';
    }
    return salesViaPlatform
      ? 'https://calendly.com/dougs-compta/rendez-vous-comptable-initial-ecommerce'
      : 'https://calendly.com/dougs-compta/rendez-vous-comptable-initial';
  }

  private getQs(salesViaPlatform: boolean | null, activeCompany: Company): Record<string, string | undefined> {
    const baseQs: Record<string, string | undefined> = { email: activeCompany.owner.email };
    const userUuid = `userUuid-${activeCompany.owner.uuid}`;

    if (salesViaPlatform === null) {
      return baseQs;
    }

    return salesViaPlatform
      ? { ...baseQs, submission_uuid: userUuid }
      : { ...baseQs, utm_source: userUuid, name: activeCompany.owner.profile?.fullName };
  }
}
