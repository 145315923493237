import { Injectable } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { DrawerService } from '@dougs/ds';
import { Task, TaskTemplate, ValidatedFile } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { AttachmentAutoValidationService } from './attachment-auto-validation.service';

@Injectable()
export class TaskFilesComponentService {
  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly attachmentValidationService: AttachmentAutoValidationService,
    private readonly drawerService: DrawerService,
  ) {}

  getAttachmentByType(task: Task, taskTemplate: TaskTemplate | null): { [key: string]: Attachment[] } {
    let attachmentByType: { [key: string]: Attachment[] } = {};
    if (task && taskTemplate) {
      attachmentByType = taskTemplate.fileAttachmentSlots.reduce(
        (acc: { [key: string]: Attachment[] }, fileAttachmentSlot) => {
          acc[fileAttachmentSlot.type] =
            task.attachments?.filter((attachment) => attachment.type === fileAttachmentSlot.type) || [];

          return acc;
        },
        {},
      );

      attachmentByType.attachment = task.attachments?.filter((attachment) => attachment.type === 'attachment') ?? [];
    }

    return attachmentByType;
  }

  async onRemoveTaskFile(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(task, attachment);
  }

  async onUploadFiles(
    task: Task,
    files: FileList,
    options?: {
      fileType?: string;
      isSubTask?: boolean;
    },
  ): Promise<void> {
    await this.uploadWithAutoValidation(task, files, options);
  }

  private async uploadWithAutoValidation(
    task: Task,
    files: FileList,
    options?: {
      isSubTask?: boolean;
      fileType?: string;
    },
  ): Promise<void> {
    const filesToUpload: ValidatedFile[] = await this.attachmentValidationService.validateFiles({
      fileList: files,
      companyId: task.companyId,
      target: task.target,
      model: {
        id: task.id,
        name: 'Task',
        fileType: options?.fileType,
      },
    });

    if (filesToUpload.length > 0) {
      await this.controlPanelTasksStateService.uploadTaskAttachmentsWithValidation(
        task,
        filesToUpload,
        {
          fileType: options?.fileType,
        },
        options?.isSubTask ?? false,
      );
    }
  }
}
