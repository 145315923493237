import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Coupon } from '../dto';
import { CouponHttpService } from '../http';

interface CouponsState {
  coupons: Coupon[];
}

@Injectable({
  providedIn: 'root',
})
export class CouponsStateService extends StateService<CouponsState> {
  constructor(
    private readonly couponsHttpService: CouponHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  coupons$: Observable<Coupon[]> = this.select((state) => state.coupons);

  async refreshCoupons(companyId: number): Promise<void> {
    try {
      const coupons = await lastValueFrom(this.couponsHttpService.getCoupons(companyId));

      this.setState({
        coupons,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addCouponFromUser(code: string): Promise<void> {
    try {
      const createdCoupon = await lastValueFrom(this.couponsHttpService.createCouponFromUser(code));

      if (createdCoupon !== null) {
        this.setState({
          coupons: [...this.state.coupons, createdCoupon],
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addCouponFromAdmin(companyId: number, code: string): Promise<void> {
    try {
      const createdCoupon = await lastValueFrom(this.couponsHttpService.createCouponFromAdmin(companyId, code));

      if (createdCoupon !== null) {
        this.setState({
          coupons: [...this.state.coupons, createdCoupon],
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteCoupon(couponId: number): Promise<void> {
    try {
      await lastValueFrom(this.couponsHttpService.deleteCoupon(couponId));

      this.setState({
        coupons: this.state.coupons.filter((coupon) => coupon.id !== couponId),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
