import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BillingInvoiceStateService, SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { BillingInvoiceComponentService } from '../../../services/billing-invoice.component.service';
import { BillingInvoiceRowComponent } from '../../billing-invoices/billing-invoice-row/billing-invoice-row.component';

@Component({
  selector: 'dougs-billing-invoices-table',
  standalone: true,
  imports: [CommonModule, BillingInvoiceRowComponent],
  templateUrl: './billing-invoices-table.component.html',
  styleUrl: './billing-invoices-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingInvoicesTableComponent {
  constructor(
    public readonly billingInvoiceStateService: BillingInvoiceStateService,
    public readonly billingInvoiceComponentService: BillingInvoiceComponentService,
    public readonly userStateService: UserStateService,
    public readonly subscriptionStateService: SubscriptionStateService,
  ) {}
}
