import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { COMPANY_FLAG, CompanyStateService } from '@dougs/company/shared';
import { DividerComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { BankCardComponent } from './bank-card/bank-card.component';
import { NextBillingComponent } from './next-billing/next-billing.component';
import { PlanComponent } from './plan/plan.component';
import { SocialPlanComponent } from './social-plan/social-plan.component';
import { SubscriptionPeriodComponent } from './subscription-period/subscription-period.component';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { TrialComponent } from './trial/trial.component';
import { VipComponent } from './vip/vip.component';

@Component({
  selector: 'dougs-subscription',
  templateUrl: './subscription.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PlanComponent,
    SocialPlanComponent,
    TrialComponent,
    DividerComponent,
    NextBillingComponent,
    SubscriptionPeriodComponent,
    BankCardComponent,
    TermOfUseComponent,
    VipComponent,
    AsyncPipe,
  ],
})
export class SubscriptionComponent {
  protected COMPANY_FLAG = COMPANY_FLAG;

  constructor(
    public readonly companyStateService: CompanyStateService,
    public readonly userStateService: UserStateService,
  ) {}
}
